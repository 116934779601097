<template>
  <section class="d-flex justify-center">
    <v-card class="mx-4 mb-4 pa-4 d-flex flex-column" elevation="2" rounded>
      <!-- Título Dados da Empresa -->
      <v-row tag="div">
        <v-col cols="12">
          <h1 class="font-weight-medium text-left cursor-default">
            Dados da Empresa
          </h1>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center flex-wrap" tag="main">
        <!-- Metade dos dados da empresa -->
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Nome Fantasia: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.nome }}</span></p>
              <p><strong>Razão Social: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.razao_social }}</span></p>
              <p><strong>CNPJ: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.cnpj }}</span></p>
              <p><strong>Natureza Jurídica: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.natureza_juridica }}</span></p>
              <p v-if="admin"><strong>Capital Social: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.capital_social }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Tipo: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.tipo }}</span></p>
              <p><strong>Abertura: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.abertura }}</span></p>
              <p><strong>Situação: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.situacao }}</span></p>
              <p><strong>Inscrição Municipal: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.inscricao_municipal }}</span></p>
              <p v-if="admin"><strong>Porte: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.porte }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Inscrição Estadual: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.inscricao_estadual }}</span></p>
              <p><strong>Optante pelo Simples Nacional: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.simples ? "Sim" : "Não" }}</span></p>
              <p><strong>ISS: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.iss ? "Paga ISS" : "Não paga ISS" }}</span></p>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <p><strong>Descrição: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.descricao }}</span></p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <!-- Título Sócios -->
      <v-row tag="div">
        <v-col class="mt-3" cols="12">
          <h1 class="font-weight-medium text-left cursor-default">
            Sócios
          </h1>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center flex-wrap" tag="main">
        <v-col v-for="socio in empresaSelecionada.informacoes.socios" :key="socio.id" cols="12" lg="3" md="4" sm="5">
          <p><strong>Sócios: </strong><span class="dado-empresa">{{ socio.nome + " - " + socio.qual }}</span></p>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <!-- Título CNAES -->
      <v-row tag="div">
        <v-col class="mt-3" cols="12">
          <h1 class="font-weight-medium text-left cursor-default">
            CNAE's
          </h1>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center flex-wrap" tag="main">
        <v-col v-for="cnae in empresaSelecionada.informacoes.cnaes" :key="cnae.id" cols="12" lg="12" md="12" sm="12">
          <p class="mb-0"><strong>CNAE's: </strong><span class="dado-empresa">{{ cnae.descricao + " - " + cnae.codigo + " - " + cnae.categoria }}</span></p>
        </v-col>
      </v-row>

      <v-divider class="mt-3"></v-divider>

      <!-- Título Contatos -->
      <v-row tag="div">
        <v-col class="mt-3" cols="12">
          <h1 class="font-weight-medium text-left cursor-default">
            Contatos
          </h1>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center flex-wrap" tag="main">
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Celular: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.celular }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Telefone: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.telefone }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Email: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.email }}</span></p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <!-- Endereço -->
      <v-row tag="div">
        <v-col class="mt-3" cols="12">
          <h1 class="font-weight-medium text-left cursor-default">
            Endereço da Matriz
          </h1>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center flex-wrap" tag="main">
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-row v-if="empresaSelecionada.informacoes.endereco">
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>CEP: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.cep }}</span></p>
              <p><strong>Estado: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.uf }}</span></p>
              <p><strong>Cidade: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.cidade }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Bairro: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.bairro }}</span></p>
              <p><strong>Rua: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.rua }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Número: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.numero }}</span></p>
              <p><strong>Complemento: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.endereco.complemento }}</span></p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <!-- Título Dados do Sistema -->
      <v-row tag="div">
        <v-col class="mt-3" cols="12">
          <h1 class="font-weight-medium text-left cursor-default">
            Dados do Sistema
          </h1>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center flex-wrap" tag="main">
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Status: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.status === "A" ? "Ativo" : "Inativo" }}</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Data de Cadastro: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.created_at | dateFormatBr() }} {{ empresaSelecionada.informacoes.created_at | timeFormatBr() }}h</span></p>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5">
              <p><strong>Data de Atualização: </strong><span class="dado-empresa">{{ empresaSelecionada.informacoes.updated_at | dateFormatBr() }} {{ empresaSelecionada.informacoes.updated_at | timeFormatBr() }}h</span></p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card>
  </section>
</template>

<script>
import SecureLS from 'secure-ls'
export default {
  name: "InformacoesGerais",
  data() {
    return {
      admin: false,
    };
  },
  created() {
    //const ls = new SecureLS();
    //return ls.get("role") === "Administrador" ? true : false
  },
  computed: {
    empresaSelecionada: {
      get() {
        return this.$store.getters["empresas/getEmpresaSelecionada"];
      },
    },
  },
};
</script>

<style scoped>
.white-p {
  white-space: normal;
  word-break: break-word;
}
.dado-empresa {
  color: rgba(0,0,0,.6) !important;
}
.dados-empresa-card {
  width: -webkit-fill-available;
}
</style>
